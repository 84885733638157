import { useEffect, useState } from "react";

export const AboutContainer = ({ heading, paragraphs = [] }) => {
  const [paragraph, setParagraph] = useState();
  function extractLink(p) {
    // Define the regex pattern to match the links
    const linkRgexp = /000(\S+)000111(\S+)111/g;

    // Find all matches of the regex pattern in the paragraph
    const matches = [...p.matchAll(linkRgexp)];

    // Replace each match with a JSX <a> element
    const replacedParagraph = p.replace(linkRgexp, (match, href, linkText) => {
      return `<a href="${href}">${linkText}</a>`;
    });

    // Parse the replaced string as JSX
    const jsxParagraph = (
      <div dangerouslySetInnerHTML={{ __html: replacedParagraph }} />
    );

    return jsxParagraph;
  }
  /*  useEffect(() => {
    const paragraph =
      "Here is a link: 000http://example.com000111Example111 and another link: 000http://example.org000111Example.org111  ";
    setParagraph(extractLink(paragraph));
  }, []);
 */
  return (
    <div className="aboutContainer">
      <h3 className="aboutSubheading">{heading}</h3>
      {paragraphs.map((paragraph) => {
        return <p>{extractLink(paragraph)}</p>;
      })}
      <p>{paragraph}</p>
    </div>
  );
};
