export const ProductDiplayer = ({
  product = {
    name: "EmpEd",
    imageUrl: "",
    about: `
    an innovative app dedicated to transforming the educational experience for institutions,
    teachers, parents, and students worldwide. EmpEd provides a comprehensive suite of premium services,
    including homework and assignment reporting, secure communication channels,
    attendance and behavior tracking, test notifications, and much more. With a focus on collaboration,
    transparency, and engagement, EmpEd enriches the academic journey for all stakeholders.
    Explore EmpEd now to revolutionize your educational ecosystem. 
      `,
    slogan: "Empowering Education through Seamless Connectivity",
  },
}) => {
  return (
    <div
      style={{
        width: "90%",
        minHeight: "50px",
        backgroundColor: "#fff",
        boxShadow: "2px 2px 4px #333",
        padding: "10px",
        borderRadius: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          columnGap: "20px",
          justifyContent: "space-between",
        }}
      >
        <div>
          <h3 style={{ color: "blue" }}>{product?.slogan}</h3>

          <p
            style={{
              fontSize: "15px",
              fontWeight: "700",
              color: "rgba(0,0,0, 0.7)",
            }}
          >
            <span
              style={{ fontWeight: "700", fontSize: "16px", color: "#000" }}
            >
              {product?.name}:{" "}
            </span>
            {product?.about}
          </p>
        </div>
        <img style={{ maxWidth: "200px" }} alt=" " src={product?.imageUrl} />
      </div>
      <a
        style={{ textDecoration: "none" }}
        href="https://emped.ml-zaria.co.za/"
        className="button"
      >
        Go To {product?.name}
      </a>
    </div>
  );
};
