export const PageDivider = () => {
  return (
    <div
      style={{
        marginBottom: "20px",
        width: "100%",
        minHeight: "100px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          height: "6px",
          backgroundColor: "grey",
          borderRadius: "80%",
          width: "15%",
          margin: "auto",
        }}
      ></div>
    </div>
  );
};
