import logo from "./assets/logo.png";
import empEdIcon from "./assets/empEdIcon.png";
import heroImage from "./assets/heroImage.jpg";
import "./App.css";
import { useRef } from "react";
import { PageDivider } from "./components/pageDivider";
import { ProductDiplayer } from "./components/productDisplayer";
import { AboutContainer } from "./components/aboutContainer";

function App() {
  const productsRef = useRef(null);

  return (
    <div
      style={{
        paddingTop: "10%",
        backgroundImage: `url(${heroImage})`,
        backgroundSize: "cover",
        height: "100vh",
      }}
      className="App"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          /* justifyContent: "center", */
          width: "100%",
          height: "100%",
          flexGrow: 1,
          flexShrink: 1,
        }}
        className="hero"
      >
        <div
          className="heroCurve"
          style={{
            backgroundColor: "aliceblue",
            width: "100%",
            borderRadius: "50%",
            border: "1px solid steelblue",
            height: "100vh",
            position: "absolute",
            top: "-50%",
            zIndex: 0,
          }}
        ></div>
        <div
          style={{
            boxShadow: "2px 3px 4px rgba(0,0,0,0.4)",
            width: "100%",
            position: "fixed",
            top: "0px",
            left: "0px",
            minHeight: "70px",
            backgroundColor: "#fff",
            zIndex: 10,
          }}
          className="heading"
        >
          <img style={{ height: "60px" }} src={logo} alt="" />
        </div>
        <h1
          style={{
            textAlign: "center",
            fontSize: "60px",
            fontWeight: "900",
            fontFamily: "cursive",
            color: "#2C2A50",
            margin: "10px",
            zIndex: 1,
          }}
        >
          Empowering Tomorrow, Today
        </h1>
        <p
          style={{
            textAlign: "center",
            maxWidth: "50%",
            fontSize: "18px",
            fontWeight: "700",
            color: "#545382",
            zIndex: 1,
          }}
        >
          Welcome to MLzaria, where innovation meets empowerment. We're on a
          mission to transform education and beyond with cutting-edge tech
          solutions.
        </p>
        <button
          style={{
            zIndex: 1,
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            fontSize: "30px",
          }}
          className="button"
          onClick={() => {
            productsRef.current.scrollIntoView();
          }}
        >
          Products
        </button>
      </div>
      <PageDivider />
      <div
        style={{
          minHeight: "60vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        ref={productsRef}
        className="products"
      >
        <h2
          style={{
            textAlign: "center",
            fontFamily: "cursive, fantasy",
            fontSize: "40px",
            fontWeight: "800",
            color: "blue",
          }}
        >
          Products
        </h2>
        <ProductDiplayer
          product={{
            name: "EmpEd",
            imageUrl: empEdIcon,
            about: `
    an innovative app dedicated to transforming the educational experience for institutions,
    teachers, parents, and students worldwide. EmpEd provides a comprehensive suite of premium services,
    including homework and assignment reporting, secure communication channels,
    attendance and behavior tracking, test notifications, and much more. With a focus on collaboration,
    transparency, and engagement, EmpEd enriches the academic journey for all stakeholders.
    Explore EmpEd now to revolutionize your educational ecosystem. 
      `,
            slogan: "Empowering Education through Seamless Connectivity",
          }}
        />
      </div>
      <PageDivider />
      <div
        className="about"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          rowGap: "20px",
        }}
      >
        <h2
          style={{
            textAlign: "center",
            fontFamily: "cursive, fantasy",
            fontSize: "40px",
            fontWeight: "800",
            color: "blue",
          }}
        >
          About
        </h2>
        <AboutContainer
          heading={"About MLzaria"}
          paragraphs={[
            `
At MLzaria, we're driven by a dual mission that fuels our passion for technology
and innovation. Our primary mission is to empower schools and educational facilities
to achieve greater productivity with less effort through the transformative
power of technology. We believe that by providing cutting-edge tech solutions,
we can enhance the educational experience for students, teachers, and parents alike.`,
            `
But our vision extends beyond education. Our secondary mission is to harness the potential
of technology to bring positive change to a wide range of institutions and individuals.
We are committed to creating solutions that make a meaningful impact in diverse sectors.`,
          ]}
        />
        <AboutContainer
          heading={"The Force Behind MLzaria"}
          paragraphs={[
            `
Hi there, I'm Lucky Njukwanyana Seshotlo, the founder of MLzaria.
My journey with MLzaria is driven by a deep passion for innovation and a
desire to make a meaningful impact in the education sector. As the sole
driving force behind the company, I'm dedicated to crafting unique and
practical solutions to address real-world challenges faced by educational
institutions, teachers, parents, and students. With MLzaria, my goal is to
revolutionize the educational experience through intuitive and transformative products.
While we may be newcomers, our commitment to excellence and relentless pursuit
of innovation promise an exciting journey ahead.`,
          ]}
        />
        <AboutContainer
          heading={"Our Flagship: empEd"}
          paragraphs={[
            `
Currently, MLzaria's flagship product is "empEd," a groundbreaking educational platform designed
to connect schools, teachers, and parents seamlessly. With a focus on enhancing communication and
collaboration, empEd offers a range of services that are not just innovative but also uniquely
tailored to the needs of educational institutions. From homework management to real-time attendance
tracking, empEd is setting new standards in educational technology.`,
          ]}
        />
        <AboutContainer
          heading={"Our Vision"}
          paragraphs={[
            `
Looking ahead, our vision for MLzaria is ambitious yet clear. We envision our products
being adopted not only in educational facilities but also in hospitals, small businesses,
and beyond. We see a future where technology bridges gaps, improves communication, and empowers
individuals and institutions to achieve their goals efficiently and effectively.`,
          ]}
        />
        <AboutContainer
          heading={"Connect With Us"}
          paragraphs={[
            `
For more information about empEd, please visit 000https://emped.ml-zaria.co.za000111EmpEd111 .
While we are in the early stages of our journey, we are committed to making a positive impact
through technology. Stay tuned for updates and further developments from MLzaria.`,
          ]}
        />
      </div>
    </div>
  );
}

export default App;
